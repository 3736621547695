import React, { useEffect, useRef, useState } from "react";
import "./css/PayInvoice.css";
import { DataTable } from "primereact/datatable";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import CardAuthPayment from "./Components/CardAuthPayment";
import EcheckAuthPayment from "./Components/EcheckAuthPayment";
import Axios from "axios";
import CommonLoaderBlue from "./Loaders/Loader/LoaderBlue";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import StripePaymentDialog from "./Components/StripeAmountDialog";
export default function PayInvoices({ setRefresh, cpData, setPayinvoicesVisible }) {
    const toast = useRef();
    const [checkedPaymentmethod, setcheckpaymentpayment] = useState("");
    const [loader, setLoader] = useState(true);
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const companyName = parseLoginRes?.companyName;
    const companyNameToCapital = companyName?.toUpperCase();
    const [unpaidInvoices, setUnpaidInvoices] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState();
    const [totalPayable, setTotalPayable] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [processingFee, setProcessingFee] = useState(0);
    const [checkNumber, setChecknumber] = useState();
    const [amountToPay, setAmountToPay] = useState(0);
    const [receiptNumber, setReceiptNumber] = useState();
    useEffect(() => {
        setSelectedRows([]);
        setUnpaidInvoices([]);
        if (cpData) {
            Axios.get(`${process.env.REACT_APP_BASE_URL}/api/web/invoices/unPaidInvoices?customerId=${cpData?._id}`)
                .then((res) => {
                    setUnpaidInvoices(res?.data?.data);
                    setLoader(false);
                })
                .catch(() => {
                    setLoader(false);
                });
        }
    }, [cpData]);
    useEffect(() => {
        const sum = selectedRows?.reduce((accumulator, currentValue) => {
            return accumulator + parseFloat(currentValue.dueAmount);
        }, 0);
        const processingfee = selectedRows?.reduce((accumulator, currentValue) => {
            return accumulator + parseFloat(currentValue.processingFee);
        }, 0);
        setTotalPayable(sum ? sum : 0);
        setTotalAmount(sum ? (processingfee ? sum + processingfee : sum) : 0);

        setProcessingFee(processingfee ? processingfee : 0);
    }, [selectedRows]);
    return (
        <div className="w-full payinvoice-main">
            <div className="note-section">
                <div className="flex note-section-container flex-wrap flex-row justify-content-left gap-4">
                    <i style={{ color: "red" }} className="pi pi-star" />
                    <p>Notes</p>
                </div>

                <div className="flex note-section-container-details flex-wrap flex-row justify-content-left gap-4">
                    <p>1:</p>
                    <p>Any additional amount greater than total due amount will be added to customer wallet instantly</p>
                </div>
            </div>
            <div className="w-full main-paying-invoice">
                <div className=" flex flex-wrap flex-row justify-content-left customer-id">
                    <p>Customer ID:</p>
                    <p>{cpData?.enrollmentId}</p>
                </div>
                <div className="w-full all-unpaid-invoices">
                    <DataTable
                        value={unpaidInvoices}
                        selection={selectedRows}
                        onSelectionChange={(e) => setSelectedRows(e.value)}
                        size="small"
                        stripedRows
                        resizableColumns
                        paginator
                        rows={10}
                        emptyMessage={
                            loader ? (
                                <div className="flex flex-wrap mt-4 items-center w-full  justify-content-center ">
                                    <CommonLoaderBlue />
                                </div>
                            ) : (
                                "No Unpaid Invoice Found"
                            )
                        }
                        rowsPerPageOptions={[25, 50]}
                    >
                        {/* <Column expander style={{ width: "3em" }} /> */}
                        {/* <Column header="SNo" style={{ width: "3em" }} body={(rowData, rowIndex) => (rowIndex + 1).toString()} /> */}

                        <Column header="#" field="number"></Column>
                        <Column header="Check All &nbsp;&nbsp;" selectionMode="multiple" style={{ width: "10rem" }} />

                        <Column
                            header="Invoice Number"
                            field="invoiceNo[0]"
                            body={(rowData) => {
                                return <p>{rowData?.invoiceNo[0]}</p>;
                            }}
                        ></Column>

                        <Column header="Invoice Date Time" field="invoiceCreateDate"></Column>

                        <Column
                            header="Due Amount ($)"
                            field="dueAmount"
                            body={(rowData) => {
                                return <p>{rowData?.dueAmount !== "" && rowData?.dueAmount !== "NaN" ? rowData?.dueAmount : "0.00"}</p>;
                            }}
                        ></Column>
                        <Column
                            header="Processing Fee ($)"
                            field="processingFee"
                            body={(rowData) => {
                                return <p>{rowData?.processingFee ? rowData?.processingFee : "0.00"}</p>;
                            }}
                        ></Column>
                    </DataTable>
                </div>
            </div>
            <h1 className="total-dues">
                Total Dues&nbsp;&nbsp;<span>{totalPayable}</span>
            </h1>
            <div className="calculation">
                <div className="calculation-flex">
                    <h1>Amount</h1>
                    <InputText
                        disabled={checkedPaymentmethod === "Credit Card" || checkedPaymentmethod === "E Check"}
                        value={checkedPaymentmethod === "E Check" || checkedPaymentmethod === "Credit Card" ? totalPayable : amountToPay}
                        onChange={(e) => {
                            setAmountToPay(e.target.value);
                        }}
                        keyfilter="num"
                    />
                </div>
                <div className="calculation-flex">
                    <h1>Processing Fee</h1>
                    <span>{processingFee}</span>
                </div>
                <div className="calculation-flex">
                    <h1>Total Amount </h1>
                    <span>{totalAmount}</span>
                </div>
                {checkedPaymentmethod === "Money Gram" || checkedPaymentmethod === "Money Order" ? (
                    <div className="calculation-flex">
                        <h1>Receipt Number</h1>
                        <InputText
                            value={receiptNumber}
                            onChange={(e) => {
                                setReceiptNumber(e.target.value);
                            }}
                            placeholder="Enter Receipt Number"
                        />
                    </div>
                ) : checkedPaymentmethod === "Check" ? (
                    <div className="calculation-flex">
                        <h1>Cheque Number</h1>
                        <InputText
                            value={checkNumber}
                            onChange={(e) => {
                                setChecknumber(e.target.value);
                            }}
                            placeholder="Enter Cheque Number"
                        />
                    </div>
                ) : (
                    ""
                )}
            </div>

            <div className="payment-types">
                <div className="payment-label">Payment Types:</div>
                <div className="payment-type-list">
                    <div className="checkbox-flex">
                        <Checkbox
                            value="Credit Card"
                            onChange={(e) => { 
                                 if(selectedRows.length > 0){
                                setReceiptNumber("");
                                setChecknumber("");
                                setcheckpaymentpayment(e.value); 
                                 } 
                                 else{ 
                                    
      toast.current.show({ severity: "error", summary: "Invoices Selecton", detail: "Select Invoice To Proceed" });
                                 }
                            }}
                            checked={checkedPaymentmethod === "Credit Card"}
                        />
                        <label className="ml-2">Credit Card</label>
                    </div>{" "}
                    <div className="checkbox-flex">
                        <Checkbox
                            value="E Check"
                            onChange={(e) => { 
                                 if(selectedRows.length > 0){
                                setcheckpaymentpayment(e.value);
                                setReceiptNumber("");
                                setChecknumber(""); 
                                 } 
                                 else{ 
                                    
      toast.current.show({ severity: "error", summary: "Invoices Selecton", detail: "Select Invoice To Proceed" });
                                 }
                            }}
                            checked={checkedPaymentmethod === "E Check"}
                        />
                        <label className="ml-2">E Check</label>
                    </div>{" "}
                    <div className="checkbox-flex">
                        <Checkbox
                            value="Cash"
                            onChange={(e) => { 
                                 if(selectedRows.length > 0){
                                setReceiptNumber("");
                                setChecknumber("");
                                setcheckpaymentpayment(e.value); 
                                 } 
                                  else{ 
                                    
      toast.current.show({ severity: "error", summary: "Invoices Selecton", detail: "Select Invoice To Proceed" });
                                  }
                            }}
                            checked={checkedPaymentmethod === "Cash"}
                        />
                        <label className="ml-2">Cash</label>
                    </div>
                    <div className="checkbox-flex">
                        <Checkbox
                            value="Wallet Balance"
                            onChange={(e) => { 
                                 if(selectedRows.length > 0){
                                setReceiptNumber("");
                                setChecknumber("");
                                 setcheckpaymentpayment(e.value);
                                 } 
                                 else{ 
                                    
      toast.current.show({ severity: "error", summary: "Invoices Selecton", detail: "Select Invoice To Proceed" });
                                 }
                              
                            }}
                            checked={checkedPaymentmethod === "Wallet Balance"}
                        />
                        <label className="ml-2">Wallet Balance</label>
                    </div>
                    <div className="checkbox-flex">
                        <Checkbox
                            value="Money Gram"
                            onChange={(e) => {  
                                if(selectedRows.length > 0){
                                setReceiptNumber("");
                                setChecknumber("");
                                setcheckpaymentpayment(e.value); 
                                } 
                                else{ 

                                    toast.current.show({ severity: "error", summary: "Invoices Selecton", detail: "Select Invoice To Proceed" });
                                }
                            }}
                            checked={checkedPaymentmethod === "Money Gram"}
                        />
                        <label className="ml-2">Money Gram</label>
                    </div>
                    <div className="checkbox-flex">
                        <Checkbox
                            value="Money Order"
                            onChange={(e) => { 
                                if(selectedRows.length > 0){
                                setReceiptNumber("");
                                setChecknumber("");
                                setcheckpaymentpayment(e.value);    
                                }
                                else{
      toast.current.show({ severity: "error", summary: "Invoices Selecton", detail: "Select Invoice To Proceed" }); 
                                }
                            }}
                            checked={checkedPaymentmethod === "Money Order"}
                        />
                        <label className="ml-2">Money Order</label>
                    </div>
                    <div className="checkbox-flex">
                        <Checkbox
                            value="Check"
                            onChange={(e) => {    
                                if(selectedRows.length > 0){
                                setReceiptNumber("");
                                setChecknumber("");
                                setcheckpaymentpayment(e.value); 
                                } 
                                else{ 
                                    
      toast.current.show({ severity: "error", summary: "Invoices Selecton", detail: "Select Invoice To Proceed" });
                                }
                            }}
                            checked={checkedPaymentmethod === "Check"}
                        />
                        <label className="ml-2">Cheque</label>
                    </div>
                </div>
            </div>
            {/* <CardAuthPayment setRefresh={setRefresh} setPayinvoicesVisible={setPayinvoicesVisible} cpData={cpData} selectedInvoices={selectedRows} amounts={totalAmount} />
             */}
            {  
             
             (checkedPaymentmethod === "Credit Card" && companyNameToCapital.includes("ZISFONE")) ||   (checkedPaymentmethod === "Credit Card" && companyNameToCapital.includes("TALKDAILY")) ? 
             <div className="card " style={{ marginTop: "30px", width: "55%" }}>
             <StripePaymentDialog setPayinvoicesVisible={setPayinvoicesVisible} setRefresh={setRefresh} cpData={cpData} selectedInvoices={selectedRows} totalAmount={totalAmount} />
         </div>
              :        
             (checkedPaymentmethod === "Credit Card" && companyNameToCapital === "MAGNET WIRELESS, INC") || (checkedPaymentmethod === "Credit Card" && companyNameToCapital === "IJ WIRELESS")  ? (
                <div className="card " style={{ marginTop: "30px", width: "55%" }}>
                    <StripePaymentDialog setPayinvoicesVisible={setPayinvoicesVisible} setRefresh={setRefresh} cpData={cpData} selectedInvoices={selectedRows} totalAmount={totalAmount} />
                </div>
            ) : checkedPaymentmethod === "E Check" ? (
                <EcheckAuthPayment setPayinvoicesVisible={setPayinvoicesVisible} setRefresh={setRefresh} cpData={cpData} selectedInvoices={selectedRows} amount={totalAmount} />
            )   :""}
            {checkedPaymentmethod !== "Credit Card" && checkedPaymentmethod !== "E Check" ? (
                <div className="button-submit-pay-invoice">
                    <Button
                        type="button"
                        onClick={() => {
                            setIsLoading(true);

                            const idArray = selectedRows.map((obj) => obj._id); 
                            if(idArray.length > 0){
                            Axios.put(`${process.env.REACT_APP_BASE_URL}/api/web/invoices/updateInvoice`, {
                                invoices: idArray,
                                invoicePaymentMethod: checkedPaymentmethod,
                                amountPaid: amountToPay,
                                customerId: cpData?._id,
                                userId: parseLoginRes?._id,
                                isWallet: checkedPaymentmethod === "Wallet Balance" ? true : false,
                                isCash: checkedPaymentmethod === "Cash" ? true : false,
                                ismoneyGram: checkedPaymentmethod === "Money Gram" ? true : false,
                                ismoneyOrder: checkedPaymentmethod === "Money Order" ? true : false,
                                receiptNumber: checkedPaymentmethod === "Money Order" || checkedPaymentmethod === "Money Gram" ? receiptNumber : undefined,
                                checkNumber: checkedPaymentmethod === "Check" ? checkNumber : undefined,
                            })
                                .then(() => {
                                    setIsLoading(false);
                                    setTimeout(() => {
                                        setRefresh();
                                        setPayinvoicesVisible(false);
                                    }, 500);
                                })
                                .catch((err) => {
                                    setIsLoading(false);
                                }); 
                            } 
                            else{ 
                                
      toast.current.show({ severity: "error", summary: "Invoices Selecton", detail: "Select Invoice To Proceed" });
                            }
                        }}
                        label="Submit"
                        disabled={isLoading}
                        icon={isLoading === true ? "pi pi-spin pi-spinner " : ""}
                        className="btn"
                    />
                </div>
            ) : (
                ""
            )}
            <Toast ref={toast} />
        </div>
    );
}
