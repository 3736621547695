import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { ToastContainer } from "react-toastify"; // Import ToastContainer and toast
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import moment from "moment/moment";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const AutoPay = ({ cpData, invoiceData, setShowAutoPay, setRefersh, setshowUpdateAutoPay }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [date, setDate] = useState(new Date());
    const [showDialog, setShowDialog] = useState(false);

    const validationSchema = Yup.object().shape({
        cardNo1: Yup.string().required("Required"),
        cardNo2: Yup.string().required("Required"),
        cardNo3: Yup.string().required("Required"),
        cardNo4: Yup.string(),
        securityCode: Yup.string().required("Required"),
        expDate: Yup.string().required("Required"),
        name: Yup.string().required("Required"),
        city: Yup.string().required("Required"),
        zip: Yup.string().required("Required"),
        address1: Yup.string().required("Required"),
        country: Yup.string().required("Required"),
        totalAmount: Yup.string().required("Required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            day: "",
            cardNo1: "",
            cardNo2: "",
            cardNo3: "",
            cardNo4: "",
            cardType: "",
            country: "",
            securityCode: "",
            expDate: "",
            name: "",
            totalAmount: "",
            city: cpData?.city,
            state: cpData?.state,
            zip: cpData?.zip,
            address1: cpData?.address1,
            address2: cpData?.address2,
            country: "United States",
        },
        onSubmit: async (values, actions) => {
            setShowDialog(true);
        },
    });

    const options = [
        { label: "Select", value: "" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" },
        { label: "10", value: "10" },
        { label: "11", value: "11" },
        { label: "12", value: "12" },
        { label: "13", value: "13" },
        { label: "14", value: "14" },
        { label: "15", value: "15" },
        { label: "16", value: "16" },
        { label: "17", value: "17" },
        { label: "18", value: "18" },
        { label: "19", value: "19" },
        { label: "20", value: "20" },
        { label: "21", value: "21" },
        { label: "22", value: "22" },
        { label: "23", value: "23" },
        { label: "24", value: "24" },
        { label: "25", value: "25" },
        { label: "26", value: "26" },
        { label: "27", value: "27" },
        { label: "28", value: "28" },
        // { label: "29", value: "29" },
        // { label: "30", value: "30" },
    ];

    useEffect(() => {
        if (formik.values.cardNo1[0] === "3") {
            formik.setFieldValue("cardType", "American Express");
        } else if (formik.values.cardNo1[0] === "4") {
            formik.setFieldValue("cardType", "Visa Card");
        } else if (formik.values.cardNo1[0] === "5") {
            formik.setFieldValue("cardType", "Master Card");
        } else {
            formik.setFieldValue("cardType", "");
        }
    }, [formik.values.cardNo1]);

    useEffect(() => {
        if (formik.values.zip && formik.values.zip.length === 5) {
            async function getData() {
                const response = await Axios.get(`${BASE_URL}/api/zipCode/getByZipCode?zipCode=${formik.values.zip}`);
                const data = response?.data?.data;
                formik.setFieldValue("city", data?.city);
                formik.setFieldValue("state", data?.abbreviation);
            }
            getData();
        }
    }, [formik.values.zip]);
    useEffect(() => {
        formik.setFieldValue("totalAmount", invoiceData[0]?.dueAmount);
    }, [invoiceData]);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const handleYesClick = async () => {
        const dataToSend = {
            customerId: cpData?._id,
            phoneNumber: cpData?.contact,
            day: formik.values.day,
            totalAmount: formik.values.totalAmount,
            cardNumber: formik.values.cardNo1 + formik.values.cardNo2 + formik.values.cardNo3 + formik.values.cardNo4,
            cardType: formik.values.cardType,
            securityCode: formik.values.securityCode,
            expirationDate: formik.values.expDate,
            nameOnCard: formik.values.name,
            city: formik.values.city,
            state: formik.values.state,
            zipCode: formik.values.zip,
            billingAddress: formik.values.address1,
            billingAddress2: formik.values.address2,
            email: cpData?.email,
            country: "US",
            userId: parseLoginRes?._id,
        };
        const dataToSend2 = {
            cardNumber: formik.values.cardNo1 + formik.values.cardNo2 + formik.values.cardNo3 + formik.values.cardNo4,
            cardType: formik.values.cardType,
            expirationDate: formik.values.expDate,
            nameOnCard: formik.values.name,
            billingAddress: formik.values.address1,
            billingAddress2: formik.values.address2,
            securityCode: formik.values.securityCode,
            customerId: cpData?._id,
        };
        if (parseLoginRes?.companyName.includes("IJ") || parseLoginRes?.companyName.includes("Magnet Wireless, Inc")) {
            setIsLoading(true);
            if (cpData?.autopaySetup) {
                try {
                    const response = await Axios.post(`${BASE_URL}/api/web/billing/add-card-details?customerId=${cpData?._id}`, dataToSend2);
                    if (response?.status === 200 || response?.status === 201) {
                        toast.success(response?.data?.message);
                        setIsLoading(false);
                        setTimeout(() => {
                            setshowUpdateAutoPay(false);
                            setRefersh(true);
                            setShowDialog(false);
                            setShowAutoPay(false);
                        }, 500);
                    }
                } catch (error) {
                    setIsLoading(false);
                    setShowDialog(false);
                    toast.error(error?.response?.data?.errorMessages[0]?.text);
                }
            } else {
                try {
                    const response = await Axios.post(`${BASE_URL}/api/web/billing/StripeautopayDetails`, dataToSend);
                    if (response?.status === 200 || response?.status === 201) {
                        toast.success(response?.data?.msg);
                        setIsLoading(false);
                        setTimeout(() => {
                            setRefersh(true);
                            setShowDialog(false);
                            setShowAutoPay(false);
                        }, 500);
                    }
                } catch (error) {
                    setIsLoading(false);
                    setShowDialog(false);
                    toast.error(error?.response?.data?.errorMessages[0]?.text);
                }
            }
        } else {
            setIsLoading(true);
            if (cpData?.autopaySetup) {
                try {
                    const response = await Axios.post(`${BASE_URL}/api/web/invoices/create-customer-payment-profile`, dataToSend2);
                    if (response?.status === 200 || response?.status === 201) {
                        toast.success(response?.data?.message);
                        setIsLoading(false);
                        setTimeout(() => {
                            setshowUpdateAutoPay(false);
                            setRefersh(true);
                            setShowDialog(false);
                            setShowAutoPay(false);
                        }, 500);
                    }
                } catch (error) {
                    setIsLoading(false);
                    setShowDialog(false);
                    toast.error(error?.response?.data?.errorMessages[0]?.text);
                }
            } else {
                try {
                    const response = await Axios.post(`${BASE_URL}/api/web/invoices/create-customer-profile`, dataToSend);
                    if (response?.status === 200 || response?.status === 201) {
                        toast.success(response?.data?.msg);
                        setIsLoading(false);
                        setTimeout(() => {
                            setRefersh(true);
                            setShowDialog(false);
                            setShowAutoPay(false);
                        }, 500);
                    }
                } catch (error) {
                    setIsLoading(false);
                    setShowDialog(false);
                    toast.error(error?.response?.data?.errorMessages[0]?.text);
                }
            }
        }
    };

    const handleNoClick = () => {
        setShowDialog(false);
    };

    const formatExpirationDate = (value) => {
        // Remove non-numeric characters
        value = value.replace(/\D/g, "");
        // Ensure value length does not exceed 6 characters
        if (value.length > 6) {
            value = value.slice(0, 6);
        }
        // Insert "-" after the second character
        if (value.length > 2) {
            value = value.slice(0, 2) + "-" + value.slice(2);
        }
        return value;
    };

    const inputRefs = {
        cardNo1: useRef(null),
        cardNo2: useRef(null),
        cardNo3: useRef(null),
        cardNo4: useRef(null),
    };

    const handleInputChange = (e, fieldName, nextField) => {
        formik.values.securityCode = "";
        formik.handleChange(e);
        const maxLength = e.target.maxLength;
        if (e.target.value.length === maxLength && nextField) {
            inputRefs[nextField].current.focus();
        }
    };

    const handleInputKeyDown = (e, fieldName, prevField) => {
        if (e.key === "Backspace" && e.target.value.length === 0 && prevField) {
            inputRefs[prevField].current.focus();
        }
    };
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <ToastContainer />
                <div className="col-12  ">
                    <div className="p-3">
                        <div className=" h-full ">
                            <div>
                                <table className="cp_table w-full">
                                    <tbody>
                                        <tr className="text-lg">
                                            <td className="w-21rem ">Enrollment Id</td>
                                            <td>{cpData?.enrollmentId}</td>
                                        </tr>

                                        <tr className="text-lg">
                                            <td>Customer Name</td>
                                            <td>{cpData?.firstName + " " + cpData?.lastName}</td>
                                        </tr>

                                        <tr className="text-lg">
                                            <td>Phone Number</td>
                                            <td>{cpData?.contact}</td>
                                        </tr>
                                        <tr className="text-lg">
                                            <td>
                                                Amount <span style={{ color: "red" }}>*</span>
                                            </td>
                                            <td>
                                                {" "}
                                                <InputText className={classNames({ "w-8rem mr-3": true, "p-invalid": isFormFieldValid("totalAmount") }, "input_text")} type="text" id="totalAmount" value={formik.values.totalAmount} onChange={formik.handleChange} keyfilter={/^\d{0,3}$/} />
                                                {getFormErrorMessage("totalAmount")}
                                            </td>
                                        </tr>
                                        <tr className="text-lg">
                                            <td>Total Tax</td>
                                            <td>$0</td>
                                        </tr>
                                        <tr className="text-lg">
                                            <td>Date</td>
                                            <td>{moment(date).format("YYYY-MM-DD")}</td>
                                        </tr>
                                        {cpData?.autopaySetup ? (
                                            " "
                                        ) : (
                                            <>
                                                <tr className="text-lg">
                                                    <td>
                                                        Day Of Month <span style={{ color: "red" }}>*</span>
                                                    </td>
                                                    <td>
                                                        <Dropdown options={options} value={formik.values.day} onChange={formik.handleChange} onBlur={formik.handleBlur} id="day" className={classNames({ "p-invalid": isFormFieldValid("day") })} /> {getFormErrorMessage("day")}
                                                    </td>
                                                </tr>{" "}
                                            </>
                                        )}

                                        <tr className="text-lg">
                                            <td className="w-21rem ">
                                                Credit Card Number <span style={{ color: "red" }}>*</span>
                                            </td>
                                            <td>
                                                <InputText
                                                    ref={inputRefs.cardNo1}
                                                    id="cardNo1"
                                                    name="cardNo1"
                                                    maxLength="4"
                                                    value={formik.values.cardNo1}
                                                    onChange={(e) => handleInputChange(e, "cardNo1", "cardNo2")}
                                                    onKeyDown={(e) => handleInputKeyDown(e, "cardNo1", null)}
                                                    placeholder="0000"
                                                    className={classNames({ "w-8rem mr-3": true, " p-invalid ": isFormFieldValid("cardNo1") })}
                                                />
                                                {getFormErrorMessage("cardNo1")}
                                                <InputText
                                                    ref={inputRefs.cardNo2}
                                                    id="cardNo2"
                                                    name="cardNo2"
                                                    maxLength={formik.values.cardType === "American Express" ? 6 : 4}
                                                    value={formik.values.cardNo2}
                                                    onChange={(e) => handleInputChange(e, "cardNo2", "cardNo3")}
                                                    onKeyDown={(e) => handleInputKeyDown(e, "cardNo2", "cardNo1")}
                                                    placeholder={formik.values.cardType === "American Express" ? "000000" : "0000"}
                                                    className={classNames({ "w-8rem mr-3": true, "p-invalid ": isFormFieldValid("cardNo2") })}
                                                />
                                                <InputText
                                                    ref={inputRefs.cardNo3}
                                                    id="cardNo3"
                                                    name="cardNo3"
                                                    maxLength={formik.values.cardType === "American Express" ? 5 : 4}
                                                    value={formik.values.cardNo3}
                                                    onChange={(e) => handleInputChange(e, "cardNo3", "cardNo4")}
                                                    onKeyDown={(e) => handleInputKeyDown(e, "cardNo3", "cardNo2")}
                                                    placeholder={formik.values.cardType === "American Express" ? "00000" : "0000"}
                                                    className={classNames({ "w-8rem mr-3": true, "p-invalid": isFormFieldValid("cardNo3") })}
                                                />
                                                {getFormErrorMessage("cardNo3")}
                                                {formik.values.cardType !== "American Express" && (
                                                    <InputText
                                                        ref={inputRefs.cardNo4}
                                                        id="cardNo4"
                                                        name="cardNo4"
                                                        maxLength="4"
                                                        value={formik.values.cardNo4}
                                                        onChange={formik.handleChange}
                                                        onKeyDown={(e) => handleInputKeyDown(e, "cardNo4", "cardNo3")}
                                                        placeholder="0000"
                                                        className={classNames({ "w-8rem mr-3": true, "p-invalid": isFormFieldValid("cardNo4") })}
                                                    />
                                                )}
                                                {formik.values.cardType !== "American Express" && getFormErrorMessage("cardNo4")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">Card Type</td>
                                            <td>
                                                <InputText className="w-15rem mr-3" type="text" id="cardType" value={formik.values.cardType} />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-lg">
                                                Security Code <span style={{ color: "red" }}>*</span>
                                            </td>
                                            <td>
                                                <InputText
                                                    className={classNames({ "w-15rem mr-3": true, "p-invalid": isFormFieldValid("securityCode") }, "input_text")}
                                                    type="password"
                                                    id="securityCode"
                                                    value={formik.values.securityCode}
                                                    onChange={formik.handleChange}
                                                    keyfilter={/^\d{0,3}$/}
                                                    maxLength={formik.values.cardType === "American Express" ? 4 : 3}
                                                />
                                                {getFormErrorMessage("securityCode")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">
                                                EXP Date <span style={{ color: "red" }}>*</span>
                                            </td>
                                            <td>
                                                <InputText
                                                    className={classNames({ "w-15rem ": true, "p-invalid": isFormFieldValid("expDate") }, "input_text")}
                                                    type="text"
                                                    id="expDate"
                                                    maxLength={7}
                                                    placeholder="MM-YYYY"
                                                    value={formatExpirationDate(formik.values.expDate)}
                                                    onChange={(e) => {
                                                        const formattedValue = formatExpirationDate(e.target.value);
                                                        formik.setFieldValue("expDate", formattedValue);
                                                    }}
                                                />
                                                {getFormErrorMessage("expDate")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">
                                                Name On Card <span style={{ color: "red" }}>*</span>
                                            </td>
                                            <td>
                                                <InputText className={classNames({ "w-15rem mr-3": true, "p-invalid": isFormFieldValid("name") }, "input_text")} type="text" id="name" value={formik.values.name} onChange={formik.handleChange} />
                                                {getFormErrorMessage("name")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">
                                                Country <span style={{ color: "red" }}>*</span>
                                            </td>
                                            <td>
                                                <InputText className={classNames({ "w-15rem mr-3": true, "p-invalid": isFormFieldValid("country") }, "input_text")} type="text" id="country" value={formik.values.country} onChange={formik.handleChange} />
                                                {getFormErrorMessage("country")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">
                                                Zip Code <span style={{ color: "red" }}>*</span>
                                            </td>
                                            <td>
                                                <InputText className={classNames({ "w-15rem mr-3": true, "p-invalid": isFormFieldValid("zip") }, "input_text")} type="text" id="zip" value={formik.values.zip} onChange={formik.handleChange} keyfilter={/^\d{0,4}$/} maxLength={5} />
                                                {getFormErrorMessage("zip")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">
                                                City <span style={{ color: "red" }}>*</span>
                                            </td>
                                            <td>
                                                <InputText className={classNames({ "w-15rem mr-3": true, "p-invalid": isFormFieldValid("city") }, "input_text")} type="text" id="city" value={formik.values.city} onChange={formik.handleChange} />
                                                {getFormErrorMessage("city")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">State</td>
                                            <td>
                                                <InputText className={classNames({ "w-15rem mr-3": true, "p-invalid": isFormFieldValid("State") }, "input_text")} type="text" id="State" disabled value={formik.values.state} onChange={formik.handleChange} />
                                                {getFormErrorMessage("State")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">
                                                Billing Address 1 <span style={{ color: "red" }}>*</span>
                                            </td>
                                            <td>
                                                <InputText className={classNames({ "w-15rem mr-3": true, "p-invalid": isFormFieldValid("address1") }, "input_text")} type="text" id="address1" value={formik.values.address1} onChange={formik.handleChange} />
                                                {getFormErrorMessage("address1")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">Billing Address 2</td>
                                            <td>
                                                <InputText className={classNames({ "w-15rem mr-3": true, "p-invalid": isFormFieldValid("address2") }, "input_text")} type="text" id="address2" value={formik.values.address2} onChange={formik.handleChange} />
                                                {getFormErrorMessage("address2")}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-content-end mr-5">
                    <Button label="SetUp" type="submit" />
                </div>
            </form>
            <Dialog
                header="Warning"
                visible={showDialog}
                style={{ width: "30vw" }}
                modal
                footer={
                    <>
                        <Button label="Yes" onClick={handleYesClick} icon={isLoading === true ? "pi pi-spin pi-spinner " : ""} disabled={isLoading} />
                        <Button label="No" onClick={handleNoClick} className="p-button-secondary" />
                    </>
                }
                onHide={() => setShowDialog(false)}
            >
                <p>Are you sure you want to set up AutoPay for this customer?</p>
            </Dialog>
        </>
    );
};

export default AutoPay;
