import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useEffect } from "react";
import Axios from "axios";
import { ToastContainer } from "react-toastify"; // Import ToastContainer and toast
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import { Toast } from "primereact/toast";
const CardAuthPayment = ({ setPayinvoicesVisible, setRefresh, cpData, amounts,totalAmount, selectedInvoices, setRefresh1 }) => {
    let toast = useRef();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const [formattedCardNumber, setFormattedCardNumber] = useState("");
    const [cvc, setCvc] = useState("");
    amounts = parseFloat(totalAmount).toFixed(2);
    const [isLoading, setIsLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        cardNumber: Yup.string().required("Please Enter Card Number"),
        cardCode: Yup.string().required("Please Enter CVC"),
        expirationDate: Yup.string().required("Please select Exp Date"),
        amount: Yup.string().required("Please Select Amount"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            amount: "",
            customerId: cpData?._id,
            amounts: amounts,
            cardNumber: "",
            cardCode: "",
            expirationDate: "",
            invoices: [],
            firstName: cpData?.firstName,
            lastName: cpData?.lastName,
            city: cpData?.isSameServiceAddress ? cpData?.city : cpData?.isNotSameServiceAddress ? cpData?.mailingCity : cpData?.isPoBoxCity ? cpData?.PoBoxCity : "",
            state: cpData?.isSameServiceAddress ? cpData?.state : cpData?.isNotSameServiceAddress ? cpData?.mailingState : cpData?.isPoBoxAddress ? cpData?.PoBoxState : "",
            zip: cpData?.isSameServiceAddress ? cpData?.zip : cpData?.isNotSameServiceAddress ? cpData?.mailingZip : cpData?.isPoBoxAddress ? cpData?.poBoxZip : "",
            country: "United States",
            address1: cpData?.isSameServiceAddress ? cpData?.address1 : cpData?.isNotSameServiceAddress ? cpData?.mailingAddress1 : cpData?.isPoBoxAddress ? cpData?.PoBoxAddress : "",
            address2: cpData?.isSameServiceAddress ? cpData?.address2 : cpData?.isNotSameServiceAddress ? cpData?.mailingAddress2 : cpData?.isPoBoxAddress ? cpData?.PoBoxAddress : "",

            userId: parseLoginRes?._id,
        },

        onSubmit: async (values, actions) => {
            const idArray = selectedInvoices.map((obj) => obj._id);
            formik.values.invoices = idArray;
            const datatosend = {};
            Object.keys(formik.values).map((item) => {
                if (item === "address1" || item === "address2") {
                } else {
                    datatosend[`${item}`] = formik.values[item];
                }
            });
            datatosend["address"] = formik.values["address1"] + formik.values["address2"];
            Axios.post(`${process.env.REACT_APP_BASE_URL}/api/web/invoices/chargeCreditCard`, datatosend)
                .then((res) => {
                    if (res?.data?.data?.responseCode === "1") {
                        toast.current.show({ severity: "success", summary: "Payment Proceed", detail: res?.data?.data?.messages[0]?.description });
                        Axios.put(`${process.env.REACT_APP_BASE_URL}/api/web/invoices/updateInvoice`, {
                            invoices: formik.values.invoices,
                            amountPaid: formik.values.amount,
                            invoicePaymentMethod: "Credit Card",
                            transId: res?.data?.data?.transId,
                            customerId: formik.values.customerId,
                            userId: parseLoginRes?._id,
                            isWallet: false,
                            ismoneyGram: false,
                            ismoneyOrder: false,
                            isMoney: false,
                        })
                            .then(() => {
                                // toast.current.show({ severity: "success", summary: "Invoices Updation", detail: "Invoices Updated Successfully" });
                                // setRefresh((prev) => !prev);
                            })
                            .catch((err) => {
                                //  toast.current.show({ severity: "error", summary: "Invoices Updation", detail: "Invoice Updation Failed" });
                            });
                        setTimeout(() => {
                            setRefresh();
                            setPayinvoicesVisible(false);
                        }, 500);
                    } else {    
                        if(res?.data?.data?.responseCode === "2"){
                        toast.current.show({ severity: "error", summary: "Payment Proceed", detail: res?.data?.data?.errors[0]?.errorText }); 
                        } 
                        else{ 
                            toast.current.show({ severity: "error", summary: "Payment Proceed", detail: res?.data?.data?.message?.text });   
                        }
                    }
                })
                .catch((err) => {
                    toast.current.show({ severity: "error", summary: "Payment Proceed", detail: err?.response?.data?.msg });
                });
        },
    });
    formik.values.amounts = amounts;
    const formatExpirationDate = (value) => {
        value = value.replace(/\D/g, "");
        if (value.length > 4) {
            value = value.slice(0, 4) + "-" + value.slice(4);
        }
        return value;
    };

    // const formatCardNumber = (value) => {
    //     return value
    //         .replace(/\D/g, "")
    //         .replace(/(.{4})/g, "$1-") // Insert hyphens after every 4 digits
    //         .trim()
    //         .slice(0, 19); // Limit to 19 characters
    // };
    const formatCardNumber = (value) => {
        const cleanedValue = value.replace(/\D/g, "");
        if (cleanedValue.startsWith("34") || cleanedValue.startsWith("37")) {
            setCvc("AMEX");
            return cleanedValue
                .replace(/(\d{4})(\d{0,6})(\d{0,5})/, function (match, p1, p2, p3) {
                    if (p3) return `${p1}-${p2}-${p3}`;
                    if (p2) return `${p1}-${p2}`;
                    return p1;
                })
                .slice(0, 17); // Max length of AMEX card format
        } else {
            setCvc("OTHER");
            return cleanedValue.replace(/(.{4})/g, "$1-").slice(0, 19);
        }
    };

    const handleCardNumberChange = (e) => {
        const formattedValue = formatCardNumber(e.target.value);
        formik.setFieldValue("cardCode", "");
        setFormattedCardNumber(formattedValue);
        formik.setFieldValue("cardNumber", formattedValue.replace(/-/g, ""));
    };

    // const handleCardNumberChange = (e) => {
    //     const formattedValue = formatCardNumber(e.target.value);
    //     setFormattedCardNumber(formattedValue);
    //     formik.setFieldValue("cardNumber", e.target.value.replace(/-/g, "")); // Remove hyphens before storing in formik state
    // };
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    useEffect(() => {
        if (formik.values.zip && formik.values.zip.length === 5) {
            async function getData() {
                const response = await Axios.get(`${BASE_URL}/api/zipCode/getByZipCode?zipCode=${formik.values.zip}`);
                const data = response?.data?.data;
                formik.setFieldValue("city", data?.city);
                formik.setFieldValue("state", data?.abbreviation);
            }
            getData();
        }
    }, [formik.values.zip]);
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <ToastContainer />
                <div className="col-12  ">
                    <div className="p-3">
                        <div className=" h-full ">
                            <div>
                                <table className="cp_table w-full">
                                    <tbody>
                                        <tr className="text-lg">
                                            <td>
                                                First Name: <span className="steric">*</span>
                                            </td>
                                            <td>
                                                {" "}
                                                <InputText type="text" id="firstName" value={formik.values.firstName} onChange={formik.handleChange} />
                                            </td>
                                        </tr>
                                        <tr className="text-lg">
                                            <td>Last Name:</td>
                                            <td>
                                                {" "}
                                                <InputText type="text" id="lastName" value={formik.values.lastName} onChange={formik.handleChange} />
                                            </td>
                                        </tr>
                                        <tr className="text-lg">
                                            <td>
                                                Total Amount: <span className="steric">*</span>
                                            </td>
                                            <td>
                                                {" "}
                                                <InputText type="text" id="amounts" value={formik.values.amounts} onChange={formik.handleChange} disabled />
                                            </td>
                                        </tr>
                                        <tr className="text-lg">
                                            <td>
                                                Amount <span className="steric">*</span>
                                            </td>
                                            <td>
                                                {" "}
                                                <InputText type="text" id="amount" value={formik.values.amount} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("amount") }, "input_text")} />
                                                {getFormErrorMessage("amount")}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-lg">
                                                Card Number <span className="steric">*</span>
                                            </td>
                                            <td>
                                                <InputText className={classNames({ " mr-3": true, "p-invalid": isFormFieldValid("cardNumber") }, "input_text")} type="text" id="cardNumber" value={formattedCardNumber} onChange={handleCardNumberChange} />
                                                {getFormErrorMessage("cardNumber")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">
                                                CVC <span className="steric">*</span>
                                            </td>
                                            <td>
                                                <InputText keyfilter="int" className={classNames({ " mr-3": true, "p-invalid": isFormFieldValid("cardCode") }, "input_text")} type="text" id="cardCode" value={formik.values.cardCode} maxLength={cvc === "AMEX" ? 4 : 3} onChange={formik.handleChange} />
                                                {getFormErrorMessage("cardCode")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">
                                                Exp Date <span className="steric">*</span>
                                            </td>
                                            <InputText
                                                className={classNames({ " ml-3": true, "p-invalid": isFormFieldValid("expirationDate") }, "input_text")}
                                                type="text"
                                                id="expirationDate"
                                                maxLength={7}
                                                placeholder="YYYY-MM"
                                                value={formatExpirationDate(formik.values.expirationDate)}
                                                onChange={(e) => {
                                                    const formattedValue = formatExpirationDate(e.target.value);
                                                    formik.setFieldValue("expirationDate", formattedValue);
                                                }}
                                            />
                                        </tr>
                                        <tr className="text-lg">
                                            <td>
                                                Country : <span className="steric">*</span>
                                            </td>
                                            <td>
                                                {" "}
                                                <InputText type="text" id="country" value={formik.values.country} onChange={formik.handleChange} />
                                            </td>
                                        </tr>
                                        <tr className="text-lg">
                                            <td>
                                                Zip Code : <span className="steric">*</span>
                                            </td>
                                            <td>
                                                {" "}
                                                <InputText type="text" id="zip" value={formik.values.zip} onChange={formik.handleChange} />
                                            </td>
                                        </tr>
                                        <tr className="text-lg">
                                            <td>
                                                City : <span className="steric">*</span>
                                            </td>
                                            <td>
                                                {" "}
                                                <InputText type="text" id="city" value={formik.values.city} onChange={formik.handleChange} />
                                            </td>
                                        </tr>
                                        <tr className="text-lg">
                                            <td>
                                                State : <span className="steric">*</span>
                                            </td>
                                            <td>
                                                {" "}
                                                <InputText type="text" id="state" value={formik.values.state} onChange={formik.handleChange} />
                                            </td>
                                        </tr>
                                        <tr className="text-lg">
                                            <td>
                                                Mailing Address1 : <span className="steric">*</span>
                                            </td>
                                            <td>
                                                {" "}
                                                <InputText type="text" id="address1" value={formik.values.address1} onChange={formik.handleChange} />
                                            </td>
                                        </tr>
                                        <tr className="text-lg">
                                            <td>Mailing Address2 :</td>
                                            <td>
                                                {" "}
                                                <InputText type="text" id="address2" value={formik.values.address2} onChange={formik.handleChange} />
                                            </td>
                                        </tr>
                                        <div className="mt-2">
                                            <Button label="Submit" className="btn" type="submit" disabled={isLoading} icon={isLoading === true ? "pi pi-spin pi-spinner " : ""} />
                                        </div>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <Toast ref={toast} />
            </form>
        </>
    );
};
export default CardAuthPayment;
